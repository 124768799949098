const HQTheme = {
  palette: {
    color: [
      {
        name: 'Jackson purple',
        hex: "#201989"
      },
      {
        name: 'Egyptian blue',
        hex: "#223D99"
      },
      {
        name: 'Lapis lazulli',
        hex: "#2566AB"
      },
      {
        name: 'Pelorous',
        hex: "#409FBD"
      },
      {
        name: 'Scooter',
        hex: "#2CC0D4"
      }
    ],
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      main: '#409FBD',
      light: '#58b5d2'
    },
    secondary: {
      main: '#1d4b96'
    },
    tertiary: {
      main: '#fff'
    },
    error: {
      main: "#f44336"
    },
    selection: {
      light: '#F0F0F0',
      dark: '#E0E0E0',
      secondary: 'rgba(64, 159, 189, 0.5)',
      tertiary: 'rgba(29, 75, 150, 0.5)'
    },
    text: {
      primary: '#fff',
      secondary: '#999',
      primaryContrast: '#409FBD',
      // secondaryContrast: '#13F4CA'
      secondaryContrast: '#1d4b96'
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd'
    }
  },
  spacing: {
    unit: 8
  }
}

export default HQTheme
